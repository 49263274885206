<template>
  <rect
    class="left"
    x="11"
    y="11"
    width="8"
    height="18"
    rx="2"
    fill="currentColor"
  />
  <path
    class="right"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23 11C21.8954 11 21 11.8954 21 13V20C21 21.1046 21.8954 22 23 22H27C28.1046 22 29 21.1046 29 20V13C29 11.8954 28.1046 11 27 11H23ZM23 24C21.8954 24 21 24.8954 21 26V27C21 28.1046 21.8954 29 23 29H27C28.1046 29 29 28.1046 29 27V26C29 24.8954 28.1046 24 27 24H23Z"
    fill="currentColor"
  />
</template>
